import FinancialReport2014 from "../src/assets/reports/BP_veiklos_ir_finansine_ataskaita_2014.pdf";
import FinancialReport2015 from "../src/assets/reports/BP_veiklos_ir_finansine_ataskaita_2015.pdf";
import FinancialReport2016 from "../src/assets/reports/BP_veiklos_ir_finansine_ataskaita_2016.pdf";
import FinancialReport2017 from "../src/assets/reports/BP_veiklos_ir_finansine_ataskaita_2017.pdf";
import FinancialReport2018 from "../src/assets/reports/BP_veiklos_ir_finansine_ataskaita_2018.pdf";
import FinancialReport2019 from "../src/assets/reports/BP_veiklos_ir_finansine_ataskaita_2019.pdf";

export const REPORT_TYPE = {
  FINANCIAL: "financial"
};

export const REPORTS = {
  [REPORT_TYPE.FINANCIAL]: [
    {
      year: "2019",
      file: FinancialReport2019
    },
    {
      year: "2018",
      file: FinancialReport2018
    },
    {
      year: "2017",
      file: FinancialReport2017
    },
    {
      year: "2016",
      file: FinancialReport2016
    },
    {
      year: "2015",
      file: FinancialReport2015
    },
    {
      year: "2014",
      file: FinancialReport2014
    }
  ]
};
