import React from "react";
import { graphql } from "gatsby";

import { REPORT_TYPE } from "../../content/reports";

import CONTACTS from "../../content/contact.json";

import { useTranslations } from "../hooks/translations";
import SEO from "../components/seo";
import ReportList from "../components/report-list";

import styles from "./donate.module.scss";
import { GPM_URL } from "../../content/donations";

export const query = graphql`
  query DonateQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        donate
      }
      reports
      ...LayoutTranslations
    }
  }
`;

const DonatePage = () => {
  const t = useTranslations("pages.donate");

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />

      <section>
        <div className="bp-container">
          <h1>{t.heading}</h1>
          <p className="bp-subheading">{t.description}</p>
          <p>{t.hereAndNow}</p>

          <p className={styles.account}>
            {CONTACTS.bankAccountName}
            <br />
            {CONTACTS.bankAccount}
            <br />
            {CONTACTS.bankName}
            <br />
            {t.paymentDetails}
          </p>

          <p>{t.gpm}</p>

          <p className={styles.account}>
            {CONTACTS.bankAccountName}
            <br />
            {t.companyCode}:{CONTACTS.companyCode}
          </p>

          <p>
            {t.gpmMore}:{" "}
            <a
              href={GPM_URL}
              target="_blank"
              rel="noreferrer noopener nofollow"
              aria-label={t.gpmMore}
              className="bp-link"
            >
              {GPM_URL}
            </a>
          </p>
        </div>
      </section>

      <section>
        <div className="bp-container">
          <h1>{t.reportHeading}</h1>

          <ReportList type={REPORT_TYPE.FINANCIAL} />
        </div>
      </section>
    </>
  );
};

export default DonatePage;
